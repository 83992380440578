import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import AxionedLogo from "../../assets/logos/axioned_logo.png";
import { HashLink as Link } from "react-router-hash-link";
import BannerImg from "../../assets/images/banner.jpg";

export default function Banner() {
  return (
    <>
      <div className="w-full bg-[#141442]">
        <img
          src={BannerImg}
          className="w-full 2xl:h-full h-96 object-cover md:object-left invisible md:visible "
        />
      </div>
      <div className="md:p-8 absolute top-0 left-0 w-full py-8 px-5">
        <nav className="flex justify-between">
          <div>
            <a href="#" title="Axioned">
              <figure className="md:w-full w-4/5">
                <img src={AxionedLogo} alt="Axioned Logo" />
              </figure>
            </a>
          </div>
          <div>
            <Link
              smooth
              to="#newsletter"
              className="inline-flex items-center font-proxima_regular rounded-md border border-transparent bg-[#E31C79] px-1 sm:px-4 py-2 md:text-base text-sm font-medium text-white shadow hover:bg-[#EB60A1]"
            >
              Axioned Insights
            </Link>
          </div>
        </nav>
      </div>
      <div className="absolute top-36 md:left-32 xl:left-20 2xl:left-48 left-0 md:w-3/5 2xl:w-1/3 xl:w-1/2 w-full text-center md:text-left px-4 md:top-32 2xl:top-40">
        <h1 className="text-4xl font-proxima_bold font-bold tracking-tight text-[#fff] sm:text-5xl xl:text-6xl">
          <span className="block xl:inline">How to tell your story</span>{" "}
          <span className="block text-[#16C6CC]">with data?</span>
        </h1>
        <p className="mx-auto mt-3 max-w-md text-[#fff] sm:text-lg md:mt-5 md:max-w-3xl md:text-xl font-proxima_regular">
          The best data storytelling takes complex data and makes it simple and
          comprehensible. That’s where we come in.
        </p>
      </div>
    </>
  );
}
