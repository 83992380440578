/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

export default function Newsletter() {
  return (
    <div className="bg-gray-50" id="newsletter">
      <div className="relative sm:py-16 py-12">
        <div className="mx-auto max-w-lg px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <iframe
            src="https://axioned.prod.kulea.marketing/pages/data-story-form"
            className="w-full rounded-2xl lg:h-80 h-[450px]"
            name="myiFrame"
            scrolling="no"
            frameBorder={1}
            marginHeight={0}
            marginWidth={0}
            width={1000}
            allowFullScreen
            title="Form"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
