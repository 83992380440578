import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useInView } from "framer-motion";

import Container from "../Container";

const reviews = [
  {
    body: "I really appreciate that the team is proactive in working out and anticipating challenges. I value their collaboration and team effort which makes their work so effective. They genuinely understand their users & are very independent. Keep up the good work & keep motivating each other. Great team!",
    author: "ClearForMe",
    rating: 5,
  },
  {
    body: "Your team is very flexible and proactive. We appreciate the work. Thank you for accommodating our collective time zones. We really appreciate it.",
    author: "The	Economist Group",
    rating: 5,
  },
  {
    body: "The team is always very helpful, thorough, and clear with user requirements and they ensure everything on the site is seamless and functional. They are a pleasure to work with.",
    author: "Heterodox Academy",
    rating: 5,
  },
  {
    body: "I am very satisfied with the team's performance. The turnaround time is less. I look forward to continued work in the future with the same zeal and enthusiasm.",
    author: "The Economist Events (Economist Impact)",
    rating: 5,
  },
  {
    body: "The team demonstrated good communication skills and always ensured they understood our specifications and priorities. They met all deadlines for all tasks. They are also very friendly.",
    author: "Heartbeat",
    rating: 5,
  },
  {
    body: "The team was very patient, flexible, prompt & prepared, & always came up with solutions/alternatives to try & help us reach a resolution, they were a real pleasure to work with.",
    author: "Coode Associates - Culture15",
    rating: 5,
  },
  {
    body: "The team is detail-oriented and excellent with the level of communication and the speed of project delivery, ensuring high-quality service. They have done a great job!",
    author: "Crain Communications",
    rating: 5,
  },
  {
    body: "The team understood what was required & did a good job documenting everything. They grasped things quickly once we got started & kept things moving. They were very friendly, personable, detail-oriented & were all contributors to the project!",
    author: "TeenLife",
    rating: 5,
  },
  {
    body: "The PAN website looks fantastic. It’s beautiful, clean, clear, and easy to use. We have been very impressed with your team’s attention to detail and rapid support. We look forward to continuing to work together. Thank you so much Axioned Team for helping us get this launched!",
    author: "Evoke KYNE Group",
    rating: 5,
  },
  {
    body: "I found our Axioned team's way of communication very helpful, e.g., weekly catch-ups and meetings notes, all of which were especially helpful to keep the project moving forward. We are happy with the outcome of the team's work and their communication on work progress.",
    author: "Economist Intelligence Unit",
    rating: 5,
  },
  {
    body: "We would like to continue working with the team. They are efficient, professional, competent, friendly, easy to work with & always look out for our best interests. The work is delivered with high quality ensuring all the deliverables are sufficiently tested. They provide great service!",
    author: "HomeServe USA",
    rating: 5,
  },
  {
    body: "The team is professional and timely with all its work. They are detailed-oriented and do a great job ensuring that they cover all work aspects and address any and all potential issues. The team is a pleasure to work with and does a great job managing all project details.",
    author: "HomeServe USA - HVAC Group",
    rating: 5,
  },
  {
    body: "They (Axioned team) identified UX issues and responded with solutions. They did a good job understanding our technical requirements and handled the changes (in direction) with grace. I would like to have the same team on a future project; their strategic thinking and support moved the project towards a better outcome.",
    author: "HomeServe USA - EV Utilities Group",
    rating: 5,
  },
  {
    body: "The Axioned team fully met our expectations. They were proactive, very patient, communicative & organized in terms of the deliverables and timeline. They also accommodated our last-minute changes. The overall process working with the team was smooth, enjoyable & GREAT! You guys consistently do a great job!",
    author: "Shutterstock",
    rating: 5,
  },
  {
    body: "The team is great to work with. They are diligent, knowledegeable, attentive to detail & are always open to feedback. We appreciate their dedication. They make sure that tasks are completed on time and are quick in understanding client requirements. Axioned and the team are great. We enjoy working with them!",
    author: "Pioneer Academics",
    rating: 5,
  },
  {
    body: "The team is always responsive and proactive in communication. Even under our client's short turnarounds, they helped us execute everything flawlessly. The process was seamless & they were able to help us adjust our design quickly and efficiently when needed to create the best UX experience. Everything went well & we look forward to working together again.",
    author: "Evoke KYNE Group",
    rating: 5,
  },
  {
    body: "The team is easy to work with. They set out goals for all deliverables and every email communication is responded to quickly, indicating that every request is being handled appropriately. They keep me on track with projects and collaborate effectively. The team understands our requirements clearly and made the vision of our project come to life. Keep up the incredible work, team!",
    author: "Soul Ryeders",
    rating: 5,
  },
];

function StarIcon(props) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  );
}

function StarRating({ rating }) {
  return (
    <div className="flex">
      {[...Array(5).keys()].map((index) => (
        <StarIcon
          key={index}
          className={clsx(
            "h-5 w-5",
            rating > index ? "fill-[#E31C79]" : "fill-gray-300"
          )}
        />
      ))}
    </div>
  );
}

function Review({ title, body, author, rating, className, ...props }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = [
      "0s",
      "0.1s",
      "0.2s",
      "0.3s",
      "0.4s",
      "0.5s",
    ];
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ];
  }, []);

  return (
    <figure
      className={clsx(
        "animate-fade-in rounded-3xl opacity-0 bg-white p-6 shadow-md shadow-gray-900/5",
        className
      )}
      style={{ animationDelay }}
      {...props}
    >
      <blockquote className="text-[#201547]">
        <StarRating rating={rating} />
        <p className="mt-3 text-base leading-7">{`"${body}"`}</p>
      </blockquote>
      <figcaption className="mt-3 text-sm text-gray-600 before:content-['–_']">
        {author}
      </figcaption>
    </figure>
  );
}

function splitArray(array, numParts) {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts;
    if (!result[index]) {
      result[index] = [];
    }
    result[index].push(array[i]);
  }
  return result;
}

function ReviewColumn({
  className,
  reviews,
  reviewClassName = () => {},
  msPerPixel = 0,
}) {
  let columnRef = useRef();
  let [columnHeight, setColumnHeight] = useState(0);
  let duration = `${columnHeight * msPerPixel}ms`;

  useEffect(() => {
    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current.offsetHeight);
    });

    resizeObserver.observe(columnRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={columnRef}
      className={clsx("animate-marquee space-y-8 py-4", className)}
      style={{ "--marquee-duration": duration }}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName(reviewIndex % reviews.length)}
          {...review}
        />
      ))}
    </div>
  );
}

function ReviewGrid() {
  let containerRef = useRef();
  let isInView = useInView(containerRef, { once: true, amount: 0.4 });
  let columns = splitArray(reviews, 3);
  columns = [columns[0], columns[1], splitArray(columns[2], 2)];

  return (
    <div
      ref={containerRef}
      className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3 font-proxima_regular"
    >
      {isInView && (
        <>
          <ReviewColumn
            reviews={[...columns[0], ...columns[2].flat(), ...columns[1]]}
            reviewClassName={(reviewIndex) =>
              clsx(
                reviewIndex >= columns[0].length + columns[2][0].length &&
                  "md:hidden",
                reviewIndex >= columns[0].length && "lg:hidden"
              )
            }
            msPerPixel={10}
          />
          <ReviewColumn
            reviews={[...columns[1], ...columns[2][1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex) =>
              reviewIndex >= columns[1].length && "lg:hidden"
            }
            msPerPixel={15}
          />
          <ReviewColumn
            reviews={columns[2].flat()}
            className="hidden lg:block"
            msPerPixel={10}
          />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-white" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-white" />
    </div>
  );
}

export default function Reviews() {
  return (
    <div
      id="reviews"
      aria-labelledby="reviews-title"
      className="md:pb-12 md:pt-6 py-8 sm:pb-12"
    >
      <Container>
        <h2
          id="reviews-title"
          className="mt-2 text-3xl font-bold leading-8 tracking-tight text-[#201547] sm:text-4xl text-center font-proxima_bold"
        >
          Client Testimonials
        </h2>
        {/* <p className='mt-2 text-lg text-gray-600 sm:text-center'>
          Thousands of people have doubled their net-worth in the last 30 days.
        </p> */}
        <ReviewGrid />
      </Container>
    </div>
  );
}
