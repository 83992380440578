/* This example requires Tailwind CSS v2.0+ */
import Heterodox from "../../assets/images/heterodox.png";
import Misk from "../../assets/images/misk.jpg";
import Consumer from "../../assets/images/consumer_international.jpg";

const posts = [
  {
    title: "Heterodox Academy",
    href: "#",
    category: {
      name: "Using data to tell stories of your organization’s achievements & future goals",
      href: "#",
    },
    description:
      "Axioned helped this client visualize and present the results of their research in a way that helped users to comprehend and interact with the data, through a desktop, tablet- and mobile-friendly user interface using web-embedded Microsoft Power BI data visuals.",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    imageUrl: Heterodox,
    readingTime: "6 min",
  },
  {
    title: "Misk Foundation’s Global Youth Index",
    href: "#",
    category: {
      name: "Using data to inspire impactful strategies to unlock the power of global youth",
      href: "#",
    },
    description:
      "Axioned was engaged to help this organization perform a revamp of their existing website’s presentation of information and data to help inspire policymakers - as well as young leaders, creators, and thinkers - to swiftly establish strategies that could unlock the potential of young people.",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",
    imageUrl: Misk,
    readingTime: "4 min",
  },
  {
    title: "Consumer International",
    href: "#",
    category: {
      name: "Using data to convey the status of consumer protection on a global scale; enabling both self-evaluation and comparison",
      href: "#",
    },
    description:
      "This project was focused on enabling countries to both observe and compare their progress with respect to upholding various consumer protection KPIs. This project involved developing and implementing an ETL (extract, transform, load) process - loading to an interactive website with multiple segmentation and drill-down options as well spider diagrams.",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
    imageUrl: Consumer,
    readingTime: "11 min",
  },
];

export default function Blog() {
  return (
    <div className="relative px-4 sm:px-6 lg:px-8 py-12">
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-[#201547] sm:text-4xl font-proxima_bold">
            Our Work
          </h2>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={post.imageUrl}
                  alt=""
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-[22px] font-semibold text-[#201547] font-proxima_bold">
                    {post.title}
                  </p>
                  <p className="mt-3 text-base font-semibold text-[#E31C79] font-proxima_regular">
                    {post.category.name}
                  </p>
                  <p className="mt-3 text-[15px] text-[#201547] font-proxima_light font-normal">
                    {post.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
