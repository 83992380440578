import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Banner, Feature, Blog, Newsletter, Reviews } from "./components";

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Banner />
        {/* <Hero /> */}
        <Feature />
        <Newsletter />
        <Blog />
        <Reviews />
      </div>
    </BrowserRouter>
  );
};

export default App;
