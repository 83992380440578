/* This example requires Tailwind CSS v2.0+ */

const features = [
  {
    name: "Slow the user’s scroll",
    description: "Eye-opening data stories that slow the users’ scroll.",
    text: "1",
  },
  {
    name: "Easy-to-share",
    description:
      " Data stories that are worth sharing [add share icon], to reach wider audiences.",
    text: "2",
  },
  {
    name: "Quick & digestible",
    description:
      "Quick, digestible data stories that enable users to grasp key messages and make decisions at a glance.",
    text: "3",
  },
  {
    name: "Simple & engaging",
    description:
      "Creating simple, engaging data stories that present complex ideas to encourage deeper analysis and alternative perspectives.",
    text: "4",
  },
];

export default function Feature() {
  return (
    <div className="bg-white py-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center relative z-10">
          <p className="mt-2 text-3xl font-bold font-proxima_bold leading-8 tracking-tight text-[#201547] sm:text-4xl">
            How can we help you?
          </p>
          <p className="mt-4 max-w-2xl text-xl text-[#201547] mx-auto font-proxima_regular">
            How do we help you compete for, and win, the time and attention of
            your audience across all platforms, and all devices?
          </p>
          <p className="mt-4 max-w-2xl text-xl text-[#201547] mx-auto font-proxima_regular">
            By creating experiences that…
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex h-12 w-12 font-extrabold text-3xl items-center justify-center rounded-md bg-[#E31C79] text-white">
                    <span>{feature.text}</span>
                  </div>
                  <p className="ml-16 text-xl font-medium leading-6 text-[#201547] font-proxima_bold">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-lg text-[#201547] font-normal font-proxima_regular">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
